<template>
	<section class="mb-5">
		<div class="row">
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>Orlov startdato</b>
					</div>
					<div class="col-12">
						<InputDate v-model="absence.absenceStartDate" :disabled="disabled" :required="true"> </InputDate>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>Orlov slutdato</b>
					</div>
					<div class="col-12">
						<InputDate v-model="absence.absenceEndDate" :disabled="disabled"> </InputDate>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>Løn under orlov</b>
					</div>
					<div class="col-12">
						<InputCheck :disabled="disabled" v-model.number="absence.salaryUnderAbsence" :required="true"> </InputCheck>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>Pensionsbidrag under orlov</b>
					</div>
					<div class="col-12">
						<InputCheck :disabled="disabled" v-model.number="absence.pensionUnderAbsence" :required="true"> </InputCheck>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<label><b>Kommentar</b></label>
					</div>
					<div class="col-12">
						<InputComment v-model="absence.comment" :disabled="disabled" />
					</div>
				</div>
			</div>
			<!--MAKE THIS v-if BASED ON Medforsikrede på sundhedsforsikring-->
		</div>
	</section>
</template>

<script>
import InputDate from '@/modules/global/atomComponents/InputDate'
import InputCheck from '@/modules/global/atomComponents/InputCheck'
import InputComment from '@/modules/global/atomComponents/InputComment'

export default {
	name: 'IndividualCondition',
	components: {
		InputDate,
		InputCheck,
		InputComment,
	},
	props: {
		value: Object,
		disabled: Boolean,
	},
	computed: {
		absence: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
	mounted() {},
}
</script>

<style></style>
